<template>
  <form class="login-form"
        @submit.prevent="connect"
        @keyup.enter="connect">
    <h4 class="login-form__title">{{$t('pages.login.rightPart.title')}}</h4>
    <div class="login-form__row">
      <text-field v-model="loginForm.email"
                  class="login-form__input login-form__input--email"
                  :label="$t('pages.login.rightPart.email.label')"
                  :hint="$t('pages.login.rightPart.email.subLabel')"
                  required
                  autocomplete="username"
                  :errorMessage="error.email" />
    </div>
    <div class="login-form__row">
      <text-field v-model="loginForm.password"
                  type="password"
                  class="login-form__input login-form__input--password"
                  :label="$t('pages.login.rightPart.password.label')"
                  :hint="$t('pages.login.rightPart.password.subLabel')"
                  required
                  autocomplete="current-password"
                  :errorMessage="error.password" />
    </div>
     <span class="login-form__error">
      {{ error.noFeatures }}
    </span>
    <div class="login-form__button o-button o-button--black o-button--hover o-button--shadows"
         @click="connect">{{$t('pages.login.rightPart.buttons.connection')}}</div>
    <span class="login-form__link">
      {{$t('pages.login.rightPart.forgotPassword')}}
    </span>
  </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

import TextField from '@/app/components/inputs/text-field.vue';

export default {
  name: 'LoginForm',
  components: { TextField },
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      error: {
        email: '',
        password: '',
        noFeatures: ''
      },
    };
  },
  validations: {
    loginForm: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    async connect() {
      this.resetErrors();
      if (this.$v.$invalid) {
        this.emptyFormError();
        this.validateEmail();
        return;
      }
      try {
        await this.$store.dispatch('user/login', {
          email: this.loginForm.email,
          password: this.loginForm.password,
        });

        if (this.user.defaultPage)
          this.$router.push({ name: this.user.defaultPage });
        else
          this.error.noFeatures = this.$t('pages.login.rightPart.error.noFeatures');
      } catch (error) {
        this.handleErrors(error.response.status);
      }
    },
    handleErrors(errorCode) {
      switch (errorCode) {
        case 404:
          this.error.email = this.$t('pages.login.rightPart.formErrors.unknownEmail');
          break;
        case 401:
          this.error.password = this.$t('pages.login.rightPart.formErrors.wrongPassword');
          break;
        default:
          break;
      }
    },
    emptyFormError() {
      this.error.email = this.loginForm.email === '' ? this.$t('pages.login.rightPart.formErrors.requiredEmail') : '';
      this.error.password = this.loginForm.password === '' ? this.$t('pages.login.rightPart.formErrors.requiredPassword') : '';
    },
    resetErrors() {
      this.error.email = '';
      this.error.password = '';
      this.error.noFeatures = '';
    },
    validateEmail() {
      const email = this.loginForm.email;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(email !== '' && !re.test(email)) {
        this.error.email = this.$t('pages.login.rightPart.formErrors.invalidEmail');
      }
    }
  },
};
</script>
<style lang="stylus" scoped>
@import '~variables'

.login-form
  margin -150px auto 0 auto
  width 40%
  .login-form__title
    margin-bottom $space-3
  .login-form__row
    margin-bottom $space-1
  .login-form__button
    margin-top $space-3
    text-align center
    text-transform uppercase
    font-size $fs-h2
  .login-form__link
    display flex
    justify-content center
    margin-top 15px
    color $dark-pink
    text-decoration underline
    font-size 1.2rem
    cursor pointer
  .login-form__error
    color $negative
</style>
