<template>
  <div class="oasis-logo-big">
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="-20 -20 800 500"
         class="oasis-logo-big__svg">
      <circle class="a"
              cx="228.5"
              cy="234.5"
              r="222.5" />
      <polygon class="a"
               points="485.4 12 742.3 457 228.5 457 485.4 12" />
    </svg>
    <span class="oasis-logo-big__title">
      OASIS by Qarnot
    </span>
  </div>
</template>

<script>
export default {
  name: 'BigOasisLogo',
};
</script>

<style lang="stylus">
@import '~variables'

.oasis-logo-big
  display flex
  flex-direction column
  justify-content center
  margin 0
  padding 0
  height 100%
  text-decoration none
  user-select none
  .oasis-logo-big__svg
    display flex
    justify-content center
    margin-right 8px
    width 200px
    border none
    fill none
    stroke $dark
    stroke-miterlimit 10
    stroke-width 30px
  .oasis-logo-big__title
    display flex
    justify-content center
    margin-top 30px
    color $dark
    text-decoration none
    font-weight 900
    font-size 2rem
</style>
