<template>
  <q-layout>
    <div class="base-container">
      <div class="col-6 logo-container">
        <span class="oasis-logo-login">
          <big-oasis-logo />
        </span>
        <div class="copyright">
          <span class="copyright__message">
            © 2018 - 2019 Qarnot-Computing
          </span>
          <span class="copyright__message">
            {{$t('pages.login.leftPart.europeanText')}}
          </span>
          <img class="europe-logo"
               :src="europeLogoSrc"
               alt="trash">
        </div>
      </div>
      <div class="col-6 login-container">
        <login-form />
      </div>
    </div>
  </q-layout>
</template>

<script>
import BigOasisLogo from '@/app/components/ui/logos/big-oasis-logo.vue';
import LoginForm from '@/app/pages/login/components/login-form.vue';

export default {
  name: 'Login',
  components: { BigOasisLogo, LoginForm },
  data() {
    return {
      europeLogoSrc: '/assets/europe-logo.png',
    };
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.base-container
  display flex
  min-height 100vh
  .logo-container, .login-container
    flex 1
  .logo-container
    position relative
    background-color $silver
    .oasis-logo-login
      display flex
      justify-content center
      margin 250px auto 0 auto
      width 300px
    .oasis-logo-version
      display flex
      justify-content center
      margin-top 20px
      color $dark
      font-size 1.4rem
    .copyright
      position absolute
      bottom 0
      display flex
      flex-direction column
      align-items center
      padding 25px
      width 100%
      .copyright__message
        margin-bottom $space-2
        max-width 450px
        color $infos
        text-align center
        font-size $fs-h2
        &:last-child
          margin-bottom 0
      .europe-logo
        width 180px
  .login-container
    display flex
    align-items center
    background-color white

@media screen and (max-width: 1000px)
  .base-container
    flex-direction column
    .logo-container
      order 2
      .oasis-logo-login
        margin 0 auto
        & >>> .oasis-logo-big
          display flex
          justify-content center
          align-items center
          margin-top $space-2
        & >>> svg
          width 120px
      .copyright
        position initial
    .login-container
      order 1
      height 80vh
      .login-form
        margin 0 auto
        margin-bottom $space-2
        & >>> h4
          font-size $fs-big
  @media screen and (max-width: 500px)
    .login-form
      width 90%
</style>
